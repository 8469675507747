import React, { useEffect } from 'react';
import styles from './Calendar.module.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';



const GetDateTimePicker = ({label, value, onChange, onError, errorMessage, minDate}) => <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
    <DateTimePicker
      label={label}
      value={value}
      onChange={onChange}
      viewRenderers={{
      hours: renderTimeViewClock,
      minutes: renderTimeViewClock,
    }}
    minDate={minDate}
    onError={onError}
      slotProps={{
        textField: {
          helperText: errorMessage,
        },
      }}
    />
  </DemoContainer>
  </LocalizationProvider>

const Calendar = ({calendarType, setDateError, setEventDates}) => {
  
  const [startDate, setStartDate] = React.useState(Date.now());
  const [error, setError] = React.useState(null);
  const nextDay = new Date();
  nextDay.setDate(new Date(startDate).getDate() + 1);
  const endDateMinDate = calendarType === 'range' ? nextDay  : undefined
  const [endDate, setEndDate] = React.useState(endDateMinDate);



  useEffect(() => {
    if(!startDate){
     return setDateError('required');
    }else{
      setDateError(null)
    }
    
    if(calendarType !== 'single' && !endDate){
     return setDateError('endDate');
    }else{
      setDateError(null)
    }
    setEventDates({
      startDate: new Date(startDate), 
      ...(endDate && calendarType !== 'single' ? { endDate: endDate } : {}),
    })
  }, [startDate, endDate, calendarType, setEventDates, setDateError]);



  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'maxDate':
      case 'minDate': {
        setDateError('End date must be after start date.');    //Translate
        return 'End date must be after start date.';  //Translate
      }

      case 'invalidDate': {
        setDateError('Your date is not valid') //Translate
        return 'Your date is not valid';  //Translate
      }

      default: {
        return '';
      }
    }
  }, [error, setDateError]);

  return(
  <div className={styles.Calendar}>
    {calendarType === 'single' && 
    <GetDateTimePicker
      value={startDate}
      onChange={(newValue) => setStartDate(newValue)}
      minDate={new Date()}
      onError={(newError) => setError(newError)}
      errorMessage={errorMessage}
    />}

    {calendarType === 'range' && 
      <div className=' py-3 row'>
        <div className='col col-md-6'>
          <GetDateTimePicker
            label={'Event Start Date'}  //Translate
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            minDate={new Date()}
            onError={(newError) => setError(newError)}
            errorMessage={errorMessage}
          />
        </div>
        <div className=' col col-md-6'>

          <GetDateTimePicker
          label={'Event End Date'}  //Translate
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            minDate={nextDay}
            onError={(newError) => setError(newError)}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    }
  </div>
)};



export default Calendar;
