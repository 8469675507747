import React from 'react';
import styles from './SoundCloud.module.css';

const SoundCloud = () => (
  <div className={styles.SoundCloud}>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1554675826&amp;auto_play=false&single_active=false`">
    </iframe>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1556031766&amp;auto_play=false&single_active=false">
    </iframe>
    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"
      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1678136109&amp;auto_play=false&single_active=false">
    </iframe>
  </div>
);


export default SoundCloud;
