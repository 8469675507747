import React, {useState} from 'react';
import logo from '../../assets/images/favicon.svg'
import { IoMenu } from "react-icons/io5";
import { GrClose } from "react-icons/gr";
import Offcanvas from 'react-bootstrap/Offcanvas';
import styles from './Nav.module.css';
import { Link } from "react-router-dom";
import TranslationDropdown from '../TranslationDropdown/TranslationDropdown'
import { useTranslation } from "react-i18next";
import { useDataCustomHook } from '../../Data/data';

const Nav = ({active, setActive}) => {
  const {t} = useTranslation();
  const data = useDataCustomHook();
  const [show, setShow] = useState(false);
  const handleActive = (name) => {
    setActive(name);
    setShow(false);
  }

  return(
  <div className={styles.Nav}>
    <div className='' style={{width: "100vw"}}>
        {/* Desktop Nav */}
      <div className='d-none d-lg-block'>
        <div className=''>
          <div className="row">
            <div className="col">
              <div className=' p-4 d-flex justify-content-between align-items-center'>
                <Link to="/" className="mb-2" onClick={() => setActive(t('home'))}>
                  <img
                    src={logo}
                    alt="djpapi Logo"
                    className="logo"
                  />
                </Link>

                <TranslationDropdown/>
                </div>
            </div>
            </div>
        </div>
      <div className='container'>
          <div className="d-flex align-items-center">
            {data.navs.map((el) => <div className="px-3 d-flex align-items-center" key={`nav_${el.name}`}>
              <Link to={el.path} className={` ${styles.navLink} ${active === el.name ? styles.isActive : ''}`} onClick={() => setActive(el.name)}>{el.name}</Link>
              {active === el.name && el.subNav && el.subNav.map((sub) => <a href={sub.path} key={`mobile_nav_sub_${sub.name}`} className={`px-3 ${styles.navLink} `}>{sub.name}</a>
              )}
            </div>)}
          </div>
      </div>

      </div>

      {/* Mobile Nav */}
      <div className={`d-block d-lg-none py-4 container`} >
        <div className='d-flex justify-content-between align-items-center'>
          <Link to="/" className="mb-2">
              <img
                src={logo}
                alt="djpapi Logo"
                className="logo"
              />
          </Link>
          <button className="btn" type="button"  onClick={() => setShow(true)}>
            <IoMenu color='white' size={30}/>
          </button>
        </div>
        <Offcanvas show={show} onHide={() => setShow(false)} placement={'end'} style={{background: '#040206'}}>
          <Offcanvas.Header><GrClose color='white' onClick={() => setShow(false)} fontSize={'2rem'} style={{marginTop: '3rem'}}/></Offcanvas.Header>
          <Offcanvas.Body>
            <div className="d-flex flex-column navbar-nav mr-auto mt-2 mt-lg-0">
              <ul class="list-group border-0">
                {data.navs.map((el) => <React.Fragment key={`mobile_nav_${el.name}`}>
                <li className={`list-group-item border-0 ${styles.navLink} ${active === el.name ? styles.isActive : ''}`} >
                  <Link to={el.path} className={` ${styles.navLink} ${active === el.name ? styles.isActive : ''}`} onClick={() => handleActive(el.name)}>
                    {el.name}
                  </Link>
                </li>
                {active === el.name && el.subNav &&  <li class= {`list-group-item border-0 ${styles.navLink}`}>
                  <ul class="list-group">
                    {el?.subNav.map(sub => <li class= {`list-group-item border-0 ${styles.navLink}`} key={`mobile_nav_sub_${sub.name}`} >
                      <a href={sub.path} key={`mobile_nav_sub_${sub.name}`} className={`${styles.navLink}`} onClick={() => setShow(false)}>
                        {sub.name}
                      </a>
                    </li>)}
                  </ul>
                </li>}
                </React.Fragment>)}
              </ul>
              <TranslationDropdown/>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  </div>
)
};


export default Nav;
