import React from 'react';
import styles from './Booking.module.css';
import BookingForm from '../../components/BookingForm/BookingForm';


const Booking = ({setNotification, setShow}) => {

  return (
    <div className={`${styles.Booking}`} data-testid="Booking">
      <BookingForm setNotification={setNotification} setShow={setShow}/>
    </div>
  );
};

export default Booking;
