import React from 'react';
import styles from './About.module.css';
import djImg from '../../assets/images/djing.png'
import Button from '../Button/Button'
import { useTranslation } from "react-i18next";
import { useDataCustomHook } from '../../Data/data';

const About = ({setActive}) =>{ 
  const {t} = useTranslation();
  const data = useDataCustomHook();
  
  return (<>
  <div className={styles.About}>
    <div className={` d-flex ${styles.aboutContainer}`}>
      <div className={`card ${styles.imgContainer}`}>
        <img src={djImg}className="img-fluid" alt="djPapi"/>
      </div>
      <div className={styles.aboutMeContainer}>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title mb-3"><b>{t('about_me_title')}</b></h5>
            <p className="card-text">{data.aboutMe}</p>
            <div className='px-4'>
        <Button 
          path={"/booking"} 
          name={t('bookNow')}  
          padding={'0.6rem 3rem'} 
          fontSize={'20px'}
          background= {'linear-gradient(180deg, #FF8AC5 0%, rgba(246.31, 205.35, 226, 0.80) 100%)'}
          onClick={() => setActive('Booking')}
        />
      </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</>
)};


export default About;
