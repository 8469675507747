import React from 'react';
import styles from './music.module.css';
import musicImg from '../../assets/images/music.png'
import musicAvatar from '../../assets/images/musicAvatar.png'
import SoundCloud from '../SoundCloud/SoundCloud';
import { useTranslation } from "react-i18next";

const Music = () => {
  const {t} = useTranslation();

  return(
  <div className={styles.Music}>
    <div className={`d-block h1 d-sm-block d-md-block d-lg-none container ${styles.listenToDJ}`}>
          {t('listen_to')}
            </div>
    <div className={`  d-flex ${styles.musicContainer}`}>
      
      <div className={`card mb-3 border-0 ${styles.musicCardContainer}`}>
        <div className="row g-0">
          <div className="col-lg-4">
            <img src={musicImg} className="img-fluid rounded-start" alt="djPapi"/>
          </div>
          <div className={`col-lg-8 d-flex ${styles.musicSoundContainer}`}>
            <div className={`d-none 	d-sm-none d-md-none d-lg-block mx-2 ${styles.listenToDJ}`}>
          {t('listen_to')}
            </div>
            <div className={` ${styles.SoundContainer}`}>
              <img src={musicAvatar}className="img-fluid" alt="djPapi"/>
              <SoundCloud/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)};

export default Music;
