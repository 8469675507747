import React from 'react';
import styles from './Button.module.css';
import { Link } from 'react-router-dom';

const Button = ({name, path, width, color, background, height, style, padding, fontSize, onClick}) => {
  const buttonStyle = {
    width,
    height,
    color,
    padding,
    fontSize,
    fontWeight: 600,
    background : background || "linear-gradient(180deg, #FF8AC5 15.58%, rgba(246, 205, 226, 0.8) 152.6%)"
  }
  return(
  <div className={styles.Button} data-testid="Button">
    <Link to={path} className="btn" style={style || buttonStyle} onClick={onClick}>{name}</Link>
  </div>
)};

export default Button;
