import React, { useState, useEffect } from 'react';
import styles from './BookingForm.module.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Dropdown from 'react-bootstrap/Dropdown';
import * as yup from "yup";
import Calendar from '../Calendar/Calendar';
import { useDataCustomHook } from '../../Data/data';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import emailjs from "@emailjs/browser"

const PHONE_NO_REGEX = /^[0-9\- ]{8,14}$/;
const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

const schema = yup.object({
  name: yup.string().required('Name is required'),
  location: yup.string().required('Location is required'),
  occasion: yup.string().required('Event Type is required'),
  message: yup.string().required('Message is required'),
  phoneNumber: yup.string()
  .required('Phone number is required')
  .min(10)
  .max(11)
  .matches(PHONE_NO_REGEX,{ message: <em>Invalid Phone Number</em> } ),
  hours: yup.number().required('Please select number of hours'),
  email: yup.string().trim().required("Email is Required").email("Invalid email").matches(emailRegex,"Invalid email"),

}).required();


const serviceKey = process.env.REACT_APP_EMAILJS_KEY;
const templateKey = process.env.REACT_APP_EMAILJS_TEMPLATE_KEY;


const BookingForm = ({setNotification, setShow}) => {
  const { register, formState: { errors }, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(schema)
  });
  const [eventType, setEventType] = useState('Single Day Event'); //Translate
  const [hours, setHours] = useState(''); //Translate
  const [dateError, setDateError] = React.useState(null);
  const [eventDates, setEventDates] = useState({startDate:'', endDate:''});
  const data = useDataCustomHook();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => emailjs.init("9afAKMERf-7jQUfYz"), []);

  const getDate = (dt) =>{
    const event = new Date(dt);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const date = event.toLocaleDateString('en-us', options)
    const time = event.toLocaleTimeString('en-US')

    return {date, time};
  }



  const onSubmit = (data) => {
      if(eventType !== 'Single Day Event' && !eventDates.endDate){
        return setDateError('required'); 
      }
      if(!dateError){
        const modifiedData = {
          ...data,
          startDate: getDate(eventDates.startDate),
          endDate : eventDates.endDate ? getDate(eventDates.endDate) : getDate(eventDates.startDate)
        }
        handleSubmitMail(modifiedData);
      };
  };



const handleSubmitMail = async (data) => {
    
  const serviceId = serviceKey;
  const templateId = templateKey;
  try {
    setLoading(true);
    await emailjs.send(serviceId, templateId, {
      contactName: data.name,
      contactEmail: data.email,
      contactPhoneNumber: data.phoneNumber,
      eventOccasion: data.occasion,
      eventLocation: data.location,
      eventStartDate: data.startDate.date,
      eventStartTime: data.startDate.time,
      eventEndDate: data.endDate.date,
      bookingDuration: data.hours,
      message: data.message
      
    });
    setNotification({
      variant: 'success',
      message:"Booking request successfully sent."});
      setShow(true)
      reset()
  } catch (error) {
    setNotification({
      variant: 'danger',
      message:"Something went wrong!"
    });
    setShow(true)
    
  } finally {
    setLoading(false);
  }
};



  return (
    <div className={styles.BookingForm}>
      <div className={`py-3 card ${styles.BookingFormContainer}`}>
        <div>
          <h3 className='text-center'><b>Booking Form</b></h3>
          <p className='text-center'><small>Please fill in the form below and I will get back to you shortly!.</small></p>
        </div>
        <form>
          <div className="container">
            <div className="m-auto row">

            <div className=" col-12 col-md-10 mb-3">
                <label htmlFor="exampleInputName1" className="form-label" >Full Name</label>
                <input type="text" className={`form-control ${styles.formInput}`} id="exampleInputName" {...register("name")}/>
                <div id="emailHelp" className="form-text">
                  {errors.name?.type === 'required' && <p role="alert" className='text-danger'>{errors.name?.message}</p>}
                </div>
              </div>

              <div className=" col-12 col-md-10 mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                <input 
                  type="email" 
                  className={`form-control ${styles.formInput}`}
                  id="exampleInputEmail1" 
                  aria-describedby="emailHelp"
                  {...register("email")}
                />
                <div id="emailHelp" className="form-text">
                  {['required', 'email', 'matches'].includes(errors.email?.type) && <p role="alert" className='text-danger'>{errors.email?.message}</p>}
                </div>
              </div>


              <div className=" col-12 col-md-10 mb-3">
                <label htmlFor="exampleInputPhoneNumber1" className="form-label">Phone Number</label>
                <input type="text" className={`form-control ${styles.formInput}`} id="exampleInputPhoneNumber1" {...register("phoneNumber")}/>
                <div id="emailHelp" className="form-text">
                  {['required', 'min', 'max', 'matches'].includes(errors.phoneNumber?.type)  && <p role="alert" className='text-danger'>{errors.phoneNumber?.message}</p>}
                </div>
              </div>


              <div className="row mb-3" style={{paddingRight: '0'}}>
                {/* Translate */}
                <label htmlFor="exampleInputPhoneNumber1" className="form-label">Event Date</label> 
                <div className='col'>
                  <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" className={styles.FormDropdown}>
                      {eventType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* Translate */}
                      <Dropdown.Item eventKey="1" onClick={()=> setEventType('Single Day Event')}>Single Day Event</Dropdown.Item>
                      <Dropdown.Item eventKey="2" onClick={()=> setEventType('Prolonged Date Event')}>Prolonged Date Event</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className={`col-12 ${eventType === 'Single Day Event' ? 'col-md-10 ' : ''} `} style={{paddingRight: '0'}}>
                  <Calendar 
                  calendarType={eventType === 'Single Day Event' ? 'single' : 'range'}
                  setDateError={setDateError}
                  setEventDates={setEventDates}
                  />
                  <div className='mt-2'>
                    <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic" className={styles.FormDropdown}>
                      {hours ? `${hours} Hour(s)` : 'Select Hours'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                    >
                      {data.hours.map(hrs => <Dropdown.Item defaultValue={hrs} value={hrs} key={`hours_${hrs}`} eventKey={hrs} 
                      onClick={() => { 
                        setHours(hrs)
                        setValue('hours', hrs, { shouldValidate: true })
                      }}
                      {...register('hours')}
                      >{hrs}</Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  </div>
                </div>
                <div id="hoursHelp" className="form-text">
                  <div id="hoursHelp" className="form-text">
                  {['required', 'optionality'].includes(errors.hours?.type) && <p role="alert" className='text-danger'>{errors.hours?.message}</p>}
                </div>
                </div>
              </div>


              <div className=" col-12 col-md-10 mb-3">
                <label htmlFor="exampleLocation" className="form-label">Event Location</label>
                <input type="text" className={`form-control ${styles.formInput}`} id="exampleLocation" {...register("location")}/>
                <div id="locationHelp" className="form-text">
                  {errors.location?.type === 'required' && <p role="alert" className='text-danger'>{errors.location?.message}</p>}
                </div>
              </div>

              <div className=" col-12 col-md-10 mb-3">
                <label htmlFor="exampleOccasion" className="form-label">Event Type</label>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    {data.eventTypes.map(el => <FormControlLabel key={`event_${el}`} value={el} control={<Radio />} label={el}  {...register("occasion")}/>)} 
                    
                  </RadioGroup>                
                <div id="occasionHelp" className="form-text">
                  {['required', 'nullable'].includes(errors.occasion?.type)  && <p role="alert" className='text-danger'>{errors.occasion?.message}</p>}
                </div>
              </div>


              <div className=" col-12 col-md-10 mb-3">
                {/* Translate */}
                <label htmlFor="exampleMessage" className="form-label">Message</label>
                <TextField
                  className="form-control"
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  {...register("message")}
                  style={{background: 'inherit'}}
                />
                <div id="messageHelp" className="form-text">
                  {errors.message?.type === 'required' && <p role="alert" className='text-danger'>{errors.message?.message}</p>}
                </div>
              </div>


              <div className='col-6'>
                {/* Translate */}
                <button type="submit"  onClick={handleSubmit(onSubmit)} className={` btn ${styles.submitBtn}`}>
                  Book Now
                  </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingForm;
