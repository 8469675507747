import { useEffect, useState } from 'react';
import { Routes, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Nav from './components/Nav/Nav';
import Home from './pages/Home/Home';
import Booking from './pages/Booking/Booking';
import Footer from './components/Footer/Footer';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';

function App() {
  const {t} = useTranslation();
  let location = useLocation();
  const [active, setActive] = useState( t('home'));
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({variant:'', message:''});

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        return setActive( t('home'))
      case '/booking':
        return setActive( t('booking'))
      default:
        break;
    }
  },[location.pathname, t])

  return (
    <div className="App">
      <Nav active={active} setActive={setActive}/>
        <Routes>
          <Route path="/"  exact element={<Home setActive={setActive} />} />
          <Route path="/booking" element={<Booking setNotification={setNotification} setShow={setShow}/>} />  
        </Routes>
      <Footer setActive={setActive}/>
      <div className='toastContainer'>
        <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide bg={notification?.variant?.toLowerCase()}>
            <Toast.Header>
            </Toast.Header>
            <Toast.Body>{notification?.message}</Toast.Body>
          </Toast>
      </div>
    </div>
  );
}

export default App;