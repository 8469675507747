import React, {useEffect, useState} from 'react';
import styles from './TranslationDropdown.module.css';
import { useTranslation } from "react-i18next";
import { useDataCustomHook } from '../../Data/data';

const TranslationDropdown = () => {
  const { i18n } = useTranslation()
  const [translation, setTranslation] = useState('🇬🇧')
  const data = useDataCustomHook();


  return(
  <div className={styles.TranslationDropdown}>
    <div className="dropdown">
  <button className="btn border-0 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{color: 'white'}}>
    {translation}
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    {data.translationList.map((el) => <li className="dropdown-item" key={`translation_${el.name}`} onClick={() => {setTranslation(el.icon); i18n.changeLanguage(el.abbrev)}}>
      {el.icon} {el.abbrev}
      </li>)}
  </ul>
</div>
  </div>
)};

export default TranslationDropdown;
