import React from 'react';
import styles from './HomeSection.module.css';
import Button from '../Button/Button';
import { useDataCustomHook } from '../../Data/data';
import { useTranslation } from "react-i18next";

const HomeSection = ({setActive}) => {
  const data = useDataCustomHook();
  const {t} = useTranslation();
  return(
  <div className={styles.HomeSection}>
    <div className={`px-4 ${styles.overLayContainer}`}>
      <div className={`mb-4 ${styles.title}`}>{data.name}</div>
      <div className={`m-4 ${styles.description}`}>
        {data.description}
      </div>
      <div className='px-4'>
        <Button 
          path={"/booking"} 
          name={t('bookNow')}  
          padding={'0.6rem 5rem'} 
          fontSize={'20px'}
          background= {'linear-gradient(180deg, #FF8AC5 0%, rgba(246.31, 205.35, 226, 0.80) 100%)'}
          onClick={() => setActive('Booking')}
        />
      </div>
    </div>
  </div>
)};

export default HomeSection;