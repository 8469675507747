import React from 'react';
import styles from './Services.module.css';
import Button from '../Button/Button';
import { useTranslation } from "react-i18next";
import { useDataCustomHook } from '../../Data/data';


const Services = ({setActive}) => {

  const {t} = useTranslation();
  const data = useDataCustomHook();

  return(
  <div className={styles.Services} data-testid="Services">
      <div className={`d-flex justify-content-center container  align-items-center ${styles.cardContainer}`}>
        {data.services.map((el) => 
          <div className={`card mx-3 my-3 ${styles.tabCard}`} key={el.name} >
         <div className="card-body">
          <h5 className="card-title text-start pb-2">{el.name}</h5>
          <ul className="pb-5">
            {el.options.map((el) =>
            <li className="" key={`services_${el}`}>{el}</li>
            )}
          </ul>
          <div className='auto text-center mb-2' style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
            <Button path={"/Booking"} name={t('bookNow')} width={'150px'} onClick={() => setActive('Booking')}/>
          </div>
        </div>
        </div>
        )}
    </div>
  </div>
)};


export default Services;
