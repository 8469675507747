import { RiInstagramLine } from "react-icons/ri";
import { FaTiktok } from "react-icons/fa6";
import { ImSoundcloud } from "react-icons/im";
import { IoLogoYoutube } from "react-icons/io";
import { useTranslation } from "react-i18next";

export const useDataCustomHook = () => {
    const {t} = useTranslation();

    const data = {
        name: 'Dj Papi',
        description: t('description'),
    
        aboutMe: t('aboutMe'),
        services: [
            {
                name: t('event_services'),
                options: [
                    t('weddings'),
                    t('private'),
                    t('corporate'),
                    t('festivals'),
                ]
            },
            {
                name: t('club_services'),
                options: [
                    t('nightClubs'),
                    t('specials'),
                ]
            },
            {
                name: t('mixtape_services'),
                options: [
                    t('creating_mixtapes'),
                    t('music_production'),
                ]
            }
        ],
        navs:[
            {
                name:  t('home'),
                path: '/',
                subNav: [
                    {
                        name: t('about'),
                        path: '/#about'
                    },
                    {
                        name: t('music'),
                        path: '/#music'
                    },
                    {
                        name: t('services'),
                        path: '/#services'
                    },
                ]
            },
            
            {
                name: t('booking'),
                path: '/booking',
            }
        ],
        venues: [
            {
                country: '',
                cities: [
                    {
                        city: 'Bucharest',
                        venue: ['Wave Club', 'Fratelli Studio 2', 'B52 Club', 'Azero Club', 'Nook Club', 'Club Agartha', 'Mao Pub', 'Amun Lounge',' Twist Garden']
                    },
                    {
                        city: 'Cluj',
                        venue: ['Club Diamond']
                    },
                    {
                        city: 'Constanta',
                        venue: ['Goblin']
                    }
                ],
            
            }
        ],
    
        media:[
            {
                name: 'instagram',
                link: 'https://instagram.com/phrancium',
                icon: <RiInstagramLine/>
    
            },
            {
                name: 'tiktok',
                link: 'https://www.tiktok.com/@callmedjpapi',
                icon: <FaTiktok />
    
            },
            {
                name: 'soundcloud-icon',
                link: 'https://soundcloud.com/callmedjpapi',
                icon: <ImSoundcloud/>
    
            },
            {
                name: 'youtube',
                link: 'https://youtube.com/@Callmedjpapi',
                icon: <IoLogoYoutube />
    
            }
        ],
        translationList:[
            {
                name: 'English',
                abbrev: 'EN',
                icon: '🇬🇧'
            },
            {
                name: 'Romanian',
                abbrev: 'RO',
                icon: '🇷🇴'
            },
            {
                name: 'French',
                abbrev: 'FR',
                icon: '🇫🇷'
            },
        ],
        eventTypes: [
            // Translate
            'Wedding', 'Private Party', 'Corporate Event',
            'Festivals', 'Club Services', 'Mixtape and Production Services', 'Others'
        ],
        hours: [1,2,4]
    }

    return data;
}

