import React from 'react';
import styles from './Footer.module.css';
import logo from '../../assets/images/favicon.svg';
import { useDataCustomHook } from '../../Data/data';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const Footer = ({setActive}) => {
  const {t} = useTranslation();
  const data = useDataCustomHook();

  return (
    <div className={styles.Footer}>
      <div className={`container d-flex ${styles.footerContainer}`}>
          <div className={`p-4 ${styles.logoContainer}`}>
          <Link to="/" className="mb-2" onClick={() => setActive(t('home'))}>
            <img
              src={logo}
              alt="djpapi Logo"
              className={styles.logo}
            />
          </Link>
          </div>
          <div className={`mx-5 d-flex ${styles.socialMediaContainer}`}>
            <div className={`${styles.socialMediaTitle}`}><span>{t('social_media')}</span></div>
              {data.media.map((el) => <div key={`Media_${el.name}`} className={`mx-2 ${styles.socialIcon} ${styles.mediaIcon}`}>
                <a href={el.link} target='_blank' rel="noreferrer" style={{color: 'white'}}>{el.icon}</a>
              </div>)}
          </div>
      </div>
    </div>
  );
};

export default Footer;

