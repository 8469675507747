import React from 'react';
import styles from './Venue.module.css';
import venue from '../../assets/images/venue.png'
import { useDataCustomHook } from '../../Data/data';
import { useTranslation } from "react-i18next";

const Venue = () => {
  const {t} = useTranslation();
  const data = useDataCustomHook();


  return(
  <div className={styles.Venue}>
    <div className={`container d-flex ${styles.venueContainer}`}>
      <div className="card border-0">
        <img src={venue} className="card-img-top" alt="venues"/>
        <div className="">
          <div className={`text-center ${styles.venuePlayed}`}><b>{t('venuePlayed').toUpperCase()}</b></div>
          <div className={`${styles.venueListContainer}`}>
            {data.venues.map((ven, i) => <React.Fragment key={`venues_${i}`}>
              {ven.cities.map((el, j) => <React.Fragment key={`venueCities_${j}`}>
                <div className={`${styles.venueCity}`}>{el.city}</div>
                  <div className={` card-body ${styles.venueList}`}>
                    <ul className="list-group list-group-flush p-0">
                      {el.venue.map((loc, k) => 
                            <li className={`list-group-item border-0 px-0 pt-1 ${styles.venueItem}`} key={`CityLocation_${k}`}>{loc}</li>
                            )}
                      </ul>
                    </div>
                
                </React.Fragment>)}
            </React.Fragment>
            )}
          </div>
        </div>
      </div>

    </div>
  </div>
)};


export default Venue;
