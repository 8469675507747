import React from 'react';
import styles from './Home.module.css';
import Services from '../../components/Services/Services'
import HomeSection from '../../components/HomeSection/HomeSection'
import About from '../../components/About/About'
import Venue from '../../components/Venue/Venue'
import Music from '../../components/music/music'

const Home = ({setActive}) => (
  <div className={`${styles.Home} ${styles.sections}`}>
    <div className={styles.sections} >
      <HomeSection setActive={setActive}/>
    </div>
    <div className={styles.sections} id="about">
      <About setActive={setActive}/>
      <Venue />
    </div>
    <div className={styles.sections} id="music">
      <Music/>
    </div>
    <div className={styles.sections} id="services">
      <Services setActive={setActive}/>
    </div>
  </div>
);

export default Home;
